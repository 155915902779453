import React from 'react';
import Layout from '../../components/layout';
import BouwbedrijfPost from '../../components/BouwbedrijfPost';
import BookDemo from '../../components/BookDemo';
import { SEO } from '../../components/SEO/SEO';

const BouwbedrijfPostPage = ({ location }) => (
  <Layout>
    <SEO location={location} pageMetadata={{ title: 'Bouwbedrijf' }} />
    <BouwbedrijfPost />
    <BookDemo />
  </Layout>
);

export default BouwbedrijfPostPage;
