import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function BouwbedrijfPost() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
              Ko Schouten
            </span>
            <span className="mt-2 pb-6 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Bouwbedrijf Novus
            </span>
          </h1>
          <figure>
            <StaticImage
              src="../img/thumbnail_bouwbedrijf.png"
              alt=""
              className="w-full rounded-lg"
            />
          </figure>
        </div>
        <div className="prose prose-indigo prose-lg text-gray-500 mx-auto">
          <blockquote className="mt-8">
            <p>
              Wat de app me nu oplevert is tijd. Nu kunnen de jongens zelf zien wat ik heb gedaan.
              Ik maak er een foto van en dan hebben ze het direct.
            </p>
          </blockquote>

          <p>
            Ko Schouten heeft sinds 4 jaar een bouwbedrjf. We doen alles wat met de bouw te maken
            heeft. Renovaties, totaal renovaties, slopen en alles uit een pand halen en opnieuw
            opbouwen tot een modern nieuw pand.
          </p>
          <p>
            De reden dat ik begon, ik deed eerst namelijk totaal iets anders.. Is omdat ik altijd
            heb gezegd dat als je hobby je werk is geworden, je iets anders moet doen. En dat heb ik
            gedaan. Wat ik het leukste vind is de afwisseling. Iedere dag is anders. Je weet
            namelijk nooit wat je tegenkomt in een oud pand.{' '}
          </p>
          <h2>Alles in één app </h2>
          <p>
            We zijn met zijn twaalven: twee compagnons en negen medewerkers. De belangrijkste reden
            dat we Homigo gebruiken is om goed te kunnen communiceren. Eerst werkten we met
            Microsoft To-do. We hadden wel dertig lijstjes waarop stond wat er gedaan moest worden,
            welke spullen er moeten worden meegenomen en wat er bijvoorbeeld uit de loods gehaald
            moet worden. Nu hebben we gelukkig alles in 1 app, wat ons ontzettend veel tijd
            bespaard.
          </p>
          <p>
            Het mooiste aan mijn werk is het moment dat ik een klus oplever aan een klant en zij
            blij zijn. Het is toch prachtig om van niets, iets te maken. En Homigo helpt ons
            daarbij.
          </p>
        </div>
      </div>
    </div>
  );
}
